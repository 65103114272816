import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { convertToEncodedURL, jsonToFormData } from "assets/usefulFunctions";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";

import {
  APaginationEntity,
  BackendDataPageShape,
  BackendPaginationRequestObject,
} from "types";
import {
  IChecklistResult,
  IGetPesudRecordReqObj,
  IGetPesudReqObj,
  IPesud,
  IPesudRecord,
  IPesudRecordCreate,
  IPesudRecordDetail,
  TPesudRecordChecklistFormInput,
} from "types/endpoints/pesud";

export const pesudStoreAPI = createApi({
  reducerPath: "pesudStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["PesudList", "PesudRecordList"],
  endpoints: (builder) => {
    return {
      restorePesudRecord: builder.mutation<
        object,
        {
          file: File[];
        }
      >({
        query: (data) => {
          const formData = jsonToFormData(data);
          return {
            url: "/pesud-record/restore",
            body: formData,
            method: "POST",
          };
        },
        invalidatesTags: ["PesudRecordList"],
      }),
      getPesudPagination: builder.query<
        APaginationEntity<IPesud[]>,
        BackendPaginationRequestObject<Partial<IGetPesudReqObj>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/pesud" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<IPesud[]>) =>
          response.data,
        providesTags: ["PesudList"],
      }),
      getPesudRecordPagination: builder.query<
        APaginationEntity<IPesudRecord[]>,
        BackendPaginationRequestObject<Partial<IGetPesudRecordReqObj>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/pesud-record" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<IPesudRecord[]>) =>
          response.data,
        providesTags: ["PesudRecordList"],
      }),
      getPesudRecordDetail: builder.query<IPesudRecordDetail, { id: number }>({
        query: ({ id }) => {
          let url = `/pesud-record/${id}`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<IPesudRecordDetail>) =>
          response.data,
        providesTags: (_, __, { id }) => [
          "PesudRecordList",
          { type: "PesudRecordList", id },
        ],
      }),
      createPesudRecord: builder.mutation<
        Pick<IPesudRecord, "id">[],
        Partial<IPesudRecordCreate>
      >({
        query: (data) => {
          return {
            url: "/pesud-record",
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["PesudRecordList"],
      }),
      deletePesudRecord: builder.mutation<
        BackendDataShape<object>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          let url = `/pesud-record/${id}`;
          return {
            url,
            method: "DELETE",
          };
        },
        invalidatesTags: ["PesudRecordList"],
      }),
      createChecklistPesudRecordCount: builder.mutation<
        BackendDataShape<IChecklistResult>,
        {
          id: number;
          data: TPesudRecordChecklistFormInput;
        }
      >({
        query: ({ id, data }) => {
          return {
            url: `/pesud-record/${id}/dummy`,
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["PesudRecordList"],
      }),
      createChecklistPesudRecord: builder.mutation<
        BackendDataShape<IChecklistResult>,
        {
          id: number;
          data: TPesudRecordChecklistFormInput;
        }
      >({
        query: ({ id, data }) => {
          return {
            url: `/pesud-record/${id}`,
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["PesudRecordList"],
      }),
    };
  },
});

export const {
  useRestorePesudRecordMutation,
  useGetPesudPaginationQuery,
  useGetPesudRecordPaginationQuery,
  useGetPesudRecordDetailQuery,
  useCreatePesudRecordMutation,
  useDeletePesudRecordMutation,
  useCreateChecklistPesudRecordCountMutation,
  useCreateChecklistPesudRecordMutation,
  util: { resetApiState: resetPesudStoreAPI },
} = pesudStoreAPI;
