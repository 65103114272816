import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";

import { convertToEncodedURL } from "assets/usefulFunctions";

import {
  APaginationEntity,
  BackendDataPageShape,
  BackendPaginationRequestObject,
} from "types";
import { IGetNewsReqObj, INews } from "types/endpoints/news";

export const newsStoreAPI = createApi({
  reducerPath: "newsStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["NewsList"],
  endpoints: (builder) => {
    return {
      getNewsPagination: builder.query<
        APaginationEntity<INews[]>,
        BackendPaginationRequestObject<Partial<IGetNewsReqObj>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/news" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<INews[]>) => {
          return response.data;
        },
        providesTags: ["NewsList"],
      }),
      getNewsDetail: builder.query<INews[], Partial<INews>>({
        query: ({ id }) => {
          let url = `/news/${id}`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<INews[]>) =>
          response.data,
        providesTags: (_, __, { id }) => ["NewsList", { type: "NewsList", id }],
      }),
      createNews: builder.mutation<Pick<INews, "id">[], Partial<INews>>({
        query: (data) => {
          return {
            url: "/news",
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["NewsList"],
      }),
      updateNews: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<INews>;
        }
      >({
        query: ({ id, data }) => {
          return {
            url: `/news/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["NewsList"],
      }),
      deleteNews: builder.mutation<
        BackendDataShape<object>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/news/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["NewsList"],
      }),
    };
  },
});

export const {
  useGetNewsPaginationQuery,
  useGetNewsDetailQuery,
  useCreateNewsMutation,
  useUpdateNewsMutation,
  useDeleteNewsMutation,
  util: { resetApiState: resetNewsStoreAPI },
} = newsStoreAPI;
