import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";
import { convertToEncodedURL } from "assets/usefulFunctions";
import {
  IGetReportPesudAgeReqObj,
  IGetReportReqObj,
  IReport,
  IReportPesudAge,
} from "types/endpoints/report";

export const reportStoreAPI = createApi({
  reducerPath: "reportStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["ReportList", "ReportPesudAgeList"],
  endpoints: (builder) => {
    return {
      getReport: builder.query<IReport[], Partial<IGetReportReqObj>>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/report" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<IReport[]>) =>
          response.data,
        providesTags: ["ReportList"],
      }),
      getReportPesudAge: builder.query<
        IReportPesudAge[],
        Partial<IGetReportPesudAgeReqObj>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/report/pesud-age" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<IReportPesudAge[]>) =>
          response.data,
        providesTags: ["ReportPesudAgeList"],
      }),
    };
  },
});

export const {
  useGetReportQuery,
  useGetReportPesudAgeQuery,
  util: { resetApiState: resetReportStoreAPI },
} = reportStoreAPI;
