import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";

import { ISatker } from "types/endpoints/satker";

export const satkerStoreAPI = createApi({
  reducerPath: "satkerStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["SatkerList"],
  endpoints: (builder) => {
    return {
      getSatker: builder.query<ISatker[], object>({
        query: () => {
          let url = "/satkers";
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<ISatker[]>) =>
          response.data,
        providesTags: ["SatkerList"],
      }),
    };
  },
});

export const {
  useGetSatkerQuery,
  util: { resetApiState: resetSatkerStoreAPI },
} = satkerStoreAPI;
