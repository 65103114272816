import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";
import { convertToEncodedURL, jsonToFormData } from "assets/usefulFunctions";
import {
  IEvaluationComponent,
  IEvaluationComponentFormSend,
  IEvaluationMaster,
  IEvaluationMasterById,
  IEvaluationParent,
  IEvaluationParentFormSend,
  IEvaluationSubComponent,
  IEvaluationSubComponentFormSend,
  IGetEvaluationMasterReqObj,
} from "types/endpoints/evaluation";

export const evaluationStoreAPI = createApi({
  reducerPath: "evaluationMasterStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["EvaluationMasterList", "EvaluationMasterById"],
  endpoints: (builder) => {
    return {
      restoreEvaluationMaster: builder.mutation<
        object,
        {
          file: File[];
        }
      >({
        query: (data) => {
          const formData = jsonToFormData(data);
          return {
            url: "/master-evaluation/restore",
            body: formData,
            method: "POST",
          };
        },
        invalidatesTags: ["EvaluationMasterList", "EvaluationMasterById"],
      }),
      getEvaluationMaster: builder.query<
        IEvaluationMaster[],
        Partial<IGetEvaluationMasterReqObj>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/master-evaluation" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<IEvaluationMaster[]>) =>
          response.data,
        providesTags: ["EvaluationMasterList"],
      }),
      getEvaluationMasterById: builder.query<
        IEvaluationMasterById,
        Partial<IGetEvaluationMasterReqObj>
      >({
        query: ({ id, ...rest }) => {
          let params = "";
          if (rest) {
            params = convertToEncodedURL(rest);
            params = params.length ? "?" + params : "";
          }
          let url = `/master-evaluation/${id}` + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: {
          statusCode: number;
          message: string;
          data: IEvaluationMasterById;
        }) => response.data,
        providesTags: (_, __, { id }) => [{ id, type: "EvaluationMasterById" }],
      }),
      createEvaluationParent: builder.mutation<
        Pick<IEvaluationParent, "id">[],
        Partial<IEvaluationParentFormSend>
      >({
        query: (data) => {
          return {
            url: "/parent",
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["EvaluationMasterById"],
      }),
      updateEvaluationParent: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<IEvaluationParentFormSend>;
        }
      >({
        query: ({ id, data }) => {
          return {
            url: `/parent/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["EvaluationMasterById"],
      }),
      deleteEvaluationParent: builder.mutation<
        BackendDataShape<object>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/parent/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["EvaluationMasterById"],
      }),
      createEvaluationComponent: builder.mutation<
        Pick<IEvaluationComponent, "id">[],
        Partial<IEvaluationComponentFormSend>
      >({
        query: (data) => {
          return {
            url: "/component",
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["EvaluationMasterById"],
      }),
      updateEvaluationComponent: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<IEvaluationComponentFormSend>;
        }
      >({
        query: ({ id, data }) => {
          return {
            url: `/component/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["EvaluationMasterById"],
      }),
      deleteEvaluationComponent: builder.mutation<
        BackendDataShape<object>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/component/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["EvaluationMasterById"],
      }),
      createEvaluationSubComponent: builder.mutation<
        Pick<IEvaluationSubComponent, "id">[],
        Partial<IEvaluationSubComponentFormSend>
      >({
        query: (data) => {
          return {
            url: "/subcomponent",
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["EvaluationMasterById"],
      }),
      updateEvaluationSubComponent: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<IEvaluationSubComponentFormSend>;
        }
      >({
        query: ({ id, data }) => {
          return {
            url: `/subcomponent/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["EvaluationMasterById"],
      }),
      deleteEvaluationSubComponent: builder.mutation<
        BackendDataShape<object>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/subcomponent/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["EvaluationMasterById"],
      }),
    };
  },
});

export const {
  useRestoreEvaluationMasterMutation,
  useGetEvaluationMasterQuery,
  useGetEvaluationMasterByIdQuery,
  useCreateEvaluationParentMutation,
  useUpdateEvaluationParentMutation,
  useDeleteEvaluationParentMutation,
  useCreateEvaluationComponentMutation,
  useUpdateEvaluationComponentMutation,
  useDeleteEvaluationComponentMutation,
  useCreateEvaluationSubComponentMutation,
  useUpdateEvaluationSubComponentMutation,
  useDeleteEvaluationSubComponentMutation,
  util: { resetApiState: resetEvaluationStoreAPI },
} = evaluationStoreAPI;
