import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";

import { convertToEncodedURL } from "assets/usefulFunctions";

import {
  APaginationEntity,
  BackendPaginationRequestObject,
  NewBackendDataShape,
} from "types";
import {
  DailyCheckupItem,
  DailyCheckupDTO,
  DailyCheckupDTOWithId,
} from "types/endpoints/dailycheckup/dailycheckup";

export const dailyCheckupStoreAPI = createApi({
  reducerPath: "dailyCheckupStore",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/daily-checkup`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Assets"],
  endpoints: (builder) => {
    return {
      getDailyCheckupById: builder.query<DailyCheckupItem, { id: number }>({
        query: ({ id }) => {
          return {
            url: `/${id}`,
            method: "GET",
          };
        },
        providesTags: (_, __, { id }) => [{ type: "Assets", id }],
        transformResponse: (res: {
          message: string;
          data: DailyCheckupItem;
        }) => {
          return res.data;
        },
      }),
      getDailyCheckup: builder.query<
        APaginationEntity<DailyCheckupItem[]>,
        BackendPaginationRequestObject<{ assetId?: number }>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          return {
            url: params,
            method: "GET",
          };
        },
        providesTags: ["Assets"],
        transformResponse: (
          res: NewBackendDataShape<APaginationEntity<DailyCheckupItem[]>>,
        ) => {
          return res.data;
        },
      }),
      createDailyCheckup: builder.mutation<
        NewBackendDataShape<undefined>,
        DailyCheckupDTO
      >({
        query: (obj) => {
          return {
            url: "",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["Assets"],
      }),
      updateDailyCheckup: builder.mutation<
        NewBackendDataShape<undefined>,
        DailyCheckupDTOWithId
      >({
        query: ({ id, ...obj }) => {
          return {
            url: `/${id}`,
            body: obj,
            method: "PATCH",
          };
        },
        invalidatesTags: (_, __, { id }) => [{ type: "Assets", id }],
      }),
      deleteDailyCheckup: builder.mutation<
        NewBackendDataShape<undefined>,
        { id: number }
      >({
        query: ({ id }) => {
          return {
            url: `/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: (_, __, { id }) => [{ type: "Assets", id }],
      }),
    };
  },
});

export const {
  useGetDailyCheckupByIdQuery,
  useGetDailyCheckupQuery,
  useCreateDailyCheckupMutation,
  useUpdateDailyCheckupMutation,
  useDeleteDailyCheckupMutation,
  util: { resetApiState: resetDailyCheckupStore },
} = dailyCheckupStoreAPI;
