import {
  Action,
  configureStore,
  isRejectedWithValue,
  Middleware,
  ThunkAction,
} from "@reduxjs/toolkit";
import { UnknownAsyncThunkRejectedWithValueAction } from "@reduxjs/toolkit/dist/matchers";
import { toast } from "react-toastify";

import sidebarStore from "./sidebarStore";
import userStore from "./userStore";
import { userStoreAPI } from "./userStore/userStoreAPI";
import { personelStoreAPI } from "./personelStore/personelStoreAPI";
import { usertypeStoreAPI } from "./usertypeStore/usertypeStoreAPI";
import { masterChecklistStoreAPI } from "./masterChecklistStore/masterChecklistStoreAPI";
import { assetStoreAPI } from "./assetsStore/assetsStoreAPI";
import { newsStoreAPI } from "./newsStore/newsStoreAPI";
import { operationCheckStoreAPI } from "./operationCheckStore/operationCheckStoreAPI";
import { personelChecklistStoreAPI } from "./personelChecklistStore/personelChecklistStoreAPI";
import { dashboardStoreAPI } from "./dashboardStore/dashboardStoreAPI";
import { dailyCheckupStoreAPI } from "./dailyCheckupStore/dailyCheckupStoreAPI";
import { notificationStoreAPI } from "./notificationStore/notificationStoreAPI";
import { evaluationStoreAPI } from "./evaluationStore/evaluationStoreAPI";
import { operasionalStoreAPI } from "./operasionalStore/operasionalStoreAPI";
import { satkerStoreAPI } from "./satkerStore/satkerStoreAPI";
import { evaluationTypeStoreAPI } from "./evalutationTypeStore/evaliationTypeStoreAPI";
import { pesudConditionStoreAPI } from "./pesudConditionStore/pesudConditionStoreAPI";
import { accidentCausalStoreAPI } from "./accidentCausalStore/accidentCausalStoreAPI";
import { pesudStoreAPI } from "./pesudStore/pesudStoreAPI";
import { pesudCategoryStoreAPI } from "./pesudCategoryStore/pesudCategoryStoreAPI";
import { pesudKindStoreAPI } from "./pesudKindStore/pesudKindStoreAPI";
import { reportStoreAPI } from "./reportStore/reportStoreAPI";
import { HIRAStoreAPI } from "./HIRAStore/HIRAStoreAPI";

import { ErrorMessageBackendDataShape } from "types";

export const store = configureStore({
  /**
   * @todo sort these reducer slices alphabetically, ascending.
   */
  reducer: {
    userStore,
    sidebarStore,
    [notificationStoreAPI.reducerPath]: notificationStoreAPI.reducer,
    [userStoreAPI.reducerPath]: userStoreAPI.reducer,
    [usertypeStoreAPI.reducerPath]: usertypeStoreAPI.reducer,
    [personelStoreAPI.reducerPath]: personelStoreAPI.reducer,
    [satkerStoreAPI.reducerPath]: satkerStoreAPI.reducer,
    [masterChecklistStoreAPI.reducerPath]: masterChecklistStoreAPI.reducer,
    [newsStoreAPI.reducerPath]: newsStoreAPI.reducer,
    [operationCheckStoreAPI.reducerPath]: operationCheckStoreAPI.reducer,
    [personelChecklistStoreAPI.reducerPath]: personelChecklistStoreAPI.reducer,
    [dashboardStoreAPI.reducerPath]: dashboardStoreAPI.reducer,
    [assetStoreAPI.reducerPath]: assetStoreAPI.reducer,
    [dailyCheckupStoreAPI.reducerPath]: dailyCheckupStoreAPI.reducer,
    [evaluationTypeStoreAPI.reducerPath]: evaluationTypeStoreAPI.reducer,
    [evaluationStoreAPI.reducerPath]: evaluationStoreAPI.reducer,
    [operasionalStoreAPI.reducerPath]: operasionalStoreAPI.reducer,
    [pesudConditionStoreAPI.reducerPath]: pesudConditionStoreAPI.reducer,
    [accidentCausalStoreAPI.reducerPath]: accidentCausalStoreAPI.reducer,
    [pesudStoreAPI.reducerPath]: pesudStoreAPI.reducer,
    [pesudCategoryStoreAPI.reducerPath]: pesudCategoryStoreAPI.reducer,
    [pesudKindStoreAPI.reducerPath]: pesudKindStoreAPI.reducer,
    [reportStoreAPI.reducerPath]: reportStoreAPI.reducer,
    [HIRAStoreAPI.reducerPath]: HIRAStoreAPI.reducer,
  },
  middleware: (getMiddleWares) => {
    /**
     * @todo remove this logger if no longer needed
     */
    const logMiddleWare: Middleware = () => {
      return (next) => {
        return (action) => {
          if (isRejectedWithValue(action)) {
            const act = action as UnknownAsyncThunkRejectedWithValueAction;
            const payload = act.payload;

            const isBEErrorPayload = (
              payload: any,
            ): payload is ErrorMessageBackendDataShape => {
              return payload?.data?.status && payload.data.status === "error";
            };
            if (isBEErrorPayload(payload)) {
              toast.error(payload.data.message);
              // console.log(payload.data.message)
            }
          }
          return next(action);
        };
      };
    };

    /**
     * @todo sort these middlewares alphabetically, ascending.
     */
    let middlewares = getMiddleWares().concat([
      logMiddleWare,
      notificationStoreAPI.middleware,
      userStoreAPI.middleware,
      usertypeStoreAPI.middleware,
      personelStoreAPI.middleware,
      satkerStoreAPI.middleware,
      masterChecklistStoreAPI.middleware,
      newsStoreAPI.middleware,
      operationCheckStoreAPI.middleware,
      personelChecklistStoreAPI.middleware,
      dashboardStoreAPI.middleware,
      assetStoreAPI.middleware,
      dailyCheckupStoreAPI.middleware,
      evaluationTypeStoreAPI.middleware,
      evaluationStoreAPI.middleware,
      operasionalStoreAPI.middleware,
      pesudConditionStoreAPI.middleware,
      accidentCausalStoreAPI.middleware,
      pesudStoreAPI.middleware,
      pesudCategoryStoreAPI.middleware,
      pesudKindStoreAPI.middleware,
      reportStoreAPI.middleware,
      HIRAStoreAPI.middleware,
    ]);
    return middlewares;
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
