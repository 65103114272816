import { createUseStyles } from "react-jss";
import { xSmall } from "../../assets/breakpoints";
import { BasicTheme, COLORS } from "../../assets/theme";

const useSidebarStyles = createUseStyles((theme: BasicTheme) => {
  return {
    container: {
      width: "64px",
      background: COLORS.k3_background,
      backdropFilter: "blur(5px)",
      display: "flex",
      flexDirection: "column",
      minHeight: 600,
      maxHeight: "100vh",
      transition: "width .3s ease",
      "&.expand": {
        width: "200px",
      },
      [xSmall]: {
        "&.expand": {
          width: "100%",
        },
      },
    },
    header: {
      background: "rgba(123, 123, 123, 0.15)",
      backdropFilter: "blur(5px)",
      flexBasis: theme.barheight,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      transition: "all .2s",
      "&.expand": {
        padding: "1.25rem 0",
      },
      "&.shorten": {
        padding: "1.25rem 0",
      },
      "& > img": {
        height: `calc(${theme.barheight} - 5px)`,
      },
      "&.shorten > img": {
        width: "25px",
      },
    },
    logoContainer: {
      color: "white",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textTransform: "capitalize",
      padding: "4rem 0",
      transition: "width .3s ease",
      gap: 4,
    },
    logoutButton: {
      border: 0,
      borderRadius: "1rem 0 0 1rem",
    },
    navItem: {
      paddingLeft: "1rem",
      "&:not(:first-child)": {
        marginTop: "15px",
      },
      "&:not(:last-child)": {
        marginBottom: "15px",
      },
      "&> button": {
        padding: ".5rem",
        borderRadius: "1rem 0 0 1rem",
      },
    },
    logoutContainer: {
      flexBasis: theme.barheight,
      display: "flex",
      flexDirection: "column",
      marginLeft: "1rem",
    },
    footer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: COLORS.white,
      paddingTop: "15px",
      paddingBottom: "15px",
      cursor: "pointer",
      fontWeight: "600",

      "&:hover": {
        opacity: 0.95,
      },
    },
    sidebarLogoWrapper: {
      marginBottom: "8px",
    },
    sideBarLogo: {
      transition: "all .2s",
      width: "36px",
      height: "36px",
      objectFit: "cover",
      borderRadius: "50%",
      "&.expand": {
        width: "52px",
        height: "52px",
      },
    },
  };
});

export default useSidebarStyles;
