import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";
import { IPesudKind } from "types/endpoints/pesud";

export const pesudKindStoreAPI = createApi({
  reducerPath: "pesudKindStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["PesudKindList"],
  endpoints: (builder) => {
    return {
      getPesudKind: builder.query<IPesudKind[], object>({
        query: () => {
          let url = "/pesud-record/list-master-pesud";
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<IPesudKind[]>) =>
          response.data,
        providesTags: ["PesudKindList"],
      }),
    };
  },
});

export const {
  useGetPesudKindQuery,
  util: { resetApiState: resetPesudKindStoreAPI },
} = pesudKindStoreAPI;
