import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";

export const accidentCausalStoreAPI = createApi({
  reducerPath: "accidentCausalStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["AccidentCausalList"],
  endpoints: (builder) => {
    return {
      getAccidentCausal: builder.query<string[], object>({
        query: () => {
          let url = "/accidents/accident-causal";
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<string[]>) =>
          response.data,
        providesTags: ["AccidentCausalList"],
      }),
    };
  },
});

export const {
  useGetAccidentCausalQuery,
  util: { resetApiState: resetAccidentCausalStoreAPI },
} = accidentCausalStoreAPI;
