import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";

import { convertToEncodedURL } from "assets/usefulFunctions";

import { IDataMasterChecklist } from "types/endpoints/masterChecklist";

export const masterChecklistStoreAPI = createApi({
  reducerPath: "masterChecklistStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["MasterChecklistList"],
  endpoints: (builder) => {
    return {
      getMasterChecklist: builder.query<
        IDataMasterChecklist[],
        Partial<IDataMasterChecklist>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/masters" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<IDataMasterChecklist[]>,
        ) => {
          let no = 0;
          return response.data.map(({ no_checklist, id, ...res }) => {
            no++;
            return {
              no_checklist: no,
              id,
              ...res,
            };
          });
        },
        providesTags: ["MasterChecklistList"],
      }),
      getMasterChecklistDetail: builder.query<
        IDataMasterChecklist[],
        Partial<IDataMasterChecklist>
      >({
        query: ({ id }) => {
          let url = `/masters/${id}`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<IDataMasterChecklist[]>,
        ) => response.data,
        providesTags: (_, __, { id }) => [
          "MasterChecklistList",
          { type: "MasterChecklistList", id },
        ],
      }),
      createMasterChecklist: builder.mutation<
        Pick<IDataMasterChecklist, "id">[],
        Partial<IDataMasterChecklist>
      >({
        query: (data) => {
          return {
            url: "/masters",
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["MasterChecklistList"],
      }),
      updateMasterChecklist: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<IDataMasterChecklist>;
        }
      >({
        query: ({ id, data }) => {
          return {
            url: `/masters/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["MasterChecklistList"],
      }),
      deleteMasterChecklist: builder.mutation<
        BackendDataShape<object>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/masters/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["MasterChecklistList"],
      }),
    };
  },
});

export const {
  useGetMasterChecklistQuery,
  useGetMasterChecklistDetailQuery,
  useCreateMasterChecklistMutation,
  useUpdateMasterChecklistMutation,
  useDeleteMasterChecklistMutation,
  util: { resetApiState: resetMasterChecklistStoreAPI },
} = masterChecklistStoreAPI;
