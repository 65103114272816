import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";

export const pesudCategoryStoreAPI = createApi({
  reducerPath: "pesudCategoryStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["PesudCategoryList"],
  endpoints: (builder) => {
    return {
      getPesudCategory: builder.query<string[], object>({
        query: () => {
          let url = "/pesud-record/list-pesud-type";
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<string[]>) =>
          response.data,
        providesTags: ["PesudCategoryList"],
      }),
    };
  },
});

export const {
  useGetPesudCategoryQuery,
  util: { resetApiState: resetPesudCategoryStoreAPI },
} = pesudCategoryStoreAPI;
