import React from "react";
import { IconType } from "react-icons";
import { BsChevronDown } from "react-icons/bs";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import {
  KnownUsertype,
  KnownUsertypeName,
} from "../../api/credentialsAPI/usertype";
import { COLORS, mq } from "../../assets/theme";
import { useAppSelector } from "../../hooks";
import {
  checkIfUsertypeAllowed,
  useUsertypePermission,
} from "../../hooks/auth/useUsertype";
import useMatchKiddo from "../../hooks/useMatchKiddo";

export interface KiddoElement {
  title: string;
  Icon?: IconType;
  shrinkTitle?: string;
  /**
   * if `undefined` or has length === 0, it will be allowed for everyone
   */
  allowedUsers?: (KnownUsertype | KnownUsertypeName)[];
  // subKiddos?: KiddoElement[];
  kiddoURL?: string;
}

export interface SidebarItemProps {
  title?: string;

  /**
   * if there is kiddo, clicking on parent item wont do any navigation
   */
  parentURL?: string;
  /**
   * if `undefined` or has length === 0, it will be allowed for everyone
   * @todo users will be complex, simple string might not suffice.
   */
  allowedUsers?: (KnownUsertype | KnownUsertypeName)[];
  Icon?: IconType;
  kiddos?: KiddoElement[];
}

const useStyles = createUseStyles(() => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
    },
    buttonStyle: {
      display: "flex",
      fontSize: 14,
      border: 0,
      alignItems: "center",
      [mq[3]]: {
        padding: "0 2rem",
      },
      padding: "0 1rem",
      color: "white",
      transition: "all .2s",
      "&.match": {
        color: COLORS.cyan_1,
        fontWeight: 600,
        "& svg": {
          color: COLORS.cyan_1,
          fill: COLORS.cyan_1,
        },
      },
      "&:hover": {
        color: COLORS.cyan_1,
        "& svg": {
          color: COLORS.cyan_1,
          fill: COLORS.cyan_1,
        },
      },
      "&:focus": {
        outline: "none",
        boxShadow: "none",
      },
      "&.shrink": {
        justifyContent: "space-between",
        paddingBottom: "10px",
        paddingTop: "10px",
        [mq[3]]: {
          padding: "1.15rem 1rem",
        },
      },
      textDecoration: "none",
      textTransform: "uppercase",
      letterSpacing: 0.45,
    },
    buttonExpandStyle: {
      display: "flex",
      border: 0,
      alignItems: "center",
      [mq[3]]: {
        padding: "0 2rem",
      },
      padding: "0 1rem",
      fontSize: 14,
      backgroundColor: "transparent",
      color: "white",
      textDecoration: "none",
      textTransform: "uppercase",
      letterSpacing: 0.45,
      transition: "all .2s",
      "&:hover": {
        color: COLORS.cyan_1,
        "& svg": {
          color: COLORS.cyan_1,
          fill: COLORS.cyan_1,
        },
      },
      "&:focus": {
        outline: "none",
        boxShadow: "none",
      },
      "&.shrink": {
        justifyContent: "space-between",
        paddingBottom: "10px",
        paddingTop: "10px",
        [mq[3]]: {
          padding: "1.15rem 1rem",
        },
      },
    },
    title: {
      flex: 1,
      margin: "1rem 0",
      textAlign: "left",
      overflowX: "hidden",
      "&.shrink": {
        textAlign: "center",
      },
    },
    chevron: {
      transform: "rotate(0deg)",
      transition: "transform .2s ease-in-out",
      "&.open": {
        transform: "rotate(-180deg)",
      },
    },
    kiddosContainer: {
      maxHeight: 0,
      transition: "all .3s",
      overflow: "hidden",
      "&.open": {
        maxHeight: "2000px", // need to be decided, scroll or view all?
      },
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    aKiddo: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      [mq[3]]: {
        padding: "0.35rem 2.75rem",
      },
      "&.shrink": {
        [mq[3]]: {
          padding: "0.35rem 2rem",
        },
      },
      padding: "0.35rem 2rem",
      textDecoration: "none",
      textTransform: "uppercase",
      letterSpacing: 0.45,
      fontSize: 14,
      color: "white",
      position: "relative",
      transition: "all .2s",
      "&:hover": {
        color: COLORS.cyan_1,
        "& svg": {
          color: COLORS.cyan_1,
          fill: COLORS.cyan_1,
        },
      },
      "&.match": {
        fontWeight: 600,
        color: COLORS.cyan_1,
        "& svg": {
          color: COLORS.cyan_1,
          fill: COLORS.cyan_1,
        },
      },
    },
  };
});

const SidebarItem: React.FunctionComponent<SidebarItemProps> = (props) => {
  const { title, parentURL, Icon, kiddos, allowedUsers } = props;
  const usertypePermission = useUsertypePermission();

  const [open, setOpen] = React.useState<boolean>(false);
  const classes = useStyles();
  // const navigate = useNavigate();
  const { match, matchKiddo } = useMatchKiddo({ kiddos, parentURL });

  // const { permission: companyPermissions } = useGetCompanyPermissions({
  //   args: {
  //     id: CookieMonster.loadCookie("companyId"),
  //   },
  //   options: {
  //     skip: usertype === "superadmin",
  //   },
  // });

  // const { data: userPermission } = useGetMyPermissionQuery(
  //   {},
  //   {
  //     skip: !usertype || usertype === "admin" || usertype === "superadmin",
  //   },
  // );

  // const checkIfAllowedInCompany = React.useCallback(
  //   (screen: string): boolean => {
  //     let usedScreen: any = screen; // to any to disable typing
  //     // return true;
  //     const { dashboard, management: mananagement, other } = companyPermissions;
  //     return (
  //       !!dashboard.find((k) => k === usedScreen) ||
  //       !!mananagement.find((k) => k === usedScreen) ||
  //       !!other.find((k) => k === usedScreen)
  //     );
  //   },
  //   [companyPermissions],
  // );

  // const checkIfAllowedToUser = React.useCallback(
  //   (screen: string): boolean => {
  //     let usedScreen: any = screen;

  //     if (!userPermission) return false;
  //     const {
  //       permissions: { dashboard, management: mananagement, other },
  //     } = userPermission;

  //     return (
  //       !!dashboard.find((k) => k === usedScreen) ||
  //       !!mananagement.find((k) => k === usedScreen) ||
  //       !!other.find((k) => k === usedScreen)
  //     );
  //   },
  //   [userPermission],
  // );

  const isExpanded = useAppSelector(
    ({ sidebarStore }) => sidebarStore.sidebarExpand,
  );
  // const isGreaterThan768 = useMediaQuery("(min-width: 768px)");
  // const dispatch = useAppDispatch();

  const listedKiddos = React.useMemo(() => {
    if (kiddos && kiddos.length) {
      let allowedKiddos: KiddoElement[] = [];
      kiddos.forEach((kid) => {
        const isKidAllowed = checkIfUsertypeAllowed(
          usertypePermission,
          kid.allowedUsers,
        );
        if (isKidAllowed) {
          allowedKiddos.push(kid);
        }
        // const { screenType, title } = kid;
        // let isScreenAllowed = true;
        // switch (usertype) {
        //   case "superadmin":
        //     isScreenAllowed = true;
        //     break;
        //   case "admin":
        //     isScreenAllowed = screenType
        //       ? checkIfAllowedInCompany(screenType)
        //       : true;
        //     break;
        //   default:
        //     isScreenAllowed = screenType
        //       ? checkIfAllowedInCompany(screenType) &&
        //         checkIfAllowedToUser(screenType)
        //       : true;
        //     break;
        // }
        // const isKidAllowed = checkIfUsertypeAllowed(usertype, kid.allowedUsers);
        // if (isKidAllowed && isScreenAllowed) {
        //   allowedKiddos.push(kid);
        // }
      });
      return allowedKiddos;
    }
    return undefined;
  }, [kiddos, usertypePermission]);
  // }, [kiddos, usertype, checkIfAllowedInCompany, checkIfAllowedToUser]);

  const allowed = React.useMemo(() => {
    // let isScreenAllowed = true;
    // switch (usertype) {
    //   case "superadmin":
    //     isScreenAllowed = true;
    //     break;
    //   case "admin":
    //     isScreenAllowed = screenType
    //       ? checkIfAllowedInCompany(screenType)
    //       : true;
    //     break;
    //   default:
    //     isScreenAllowed = screenType
    //       ? checkIfAllowedInCompany(screenType) &&
    //         checkIfAllowedToUser(screenType)
    //       : true;
    //     break;
    // }
    const isParentAllowed = checkIfUsertypeAllowed(
      usertypePermission,
      allowedUsers,
    );
    if (listedKiddos && listedKiddos.length) {
      return isParentAllowed && listedKiddos.length > 0;
    }
    return isParentAllowed;
  }, [usertypePermission, allowedUsers, listedKiddos]);
  // }, [
  //   usertype,
  //   allowedUsers,
  //   listedKiddos,
  //   screenType,
  //   checkIfAllowedInCompany,
  //   checkIfAllowedToUser,
  // ]);
  // return (
  //   <div className={[classes.container].join(" ")}>
  //     {!kiddos || kiddos.length === 0 ? (
  //       <Link
  //         to={{
  //           pathname: parentURL,
  //         }}
  //         className={[
  //           classes.buttonStyle,
  //           open ? "open" : undefined,
  //           match ? "match" : undefined,
  //           !isExpanded ? "shrink" : undefined,
  //         ].join(" ")}
  //         // onClick={(e) => {
  //         //   e.stopPropagation();
  //         //   setOpen((val) => !val);
  //         //   if (parentURL) {
  //         //     navigate(parentURL);
  //         //   }
  //         // }}
  //       >
  //         <span
  //           style={{
  //             marginRight: isExpanded ? "8.5px" : 0,
  //             flexBasis: "1rem",
  //           }}
  //         >
  //           {Icon ? <Icon /> : []}
  //         </span>
  //         {isExpanded && (
  //           <span
  //             className={[classes.title, !isExpanded ? "shrink" : ""].join(" ")}
  //           >
  //             <p style={{ margin: 0, width: "max-content" }}>{title}</p>
  //           </span>
  //         )}
  //       </Link>
  //     ) : (
  //       <button
  //         className={[
  //           classes.buttonExpandStyle,
  //           open && "open",
  //           !isExpanded && "shrink",
  //         ].join(" ")}
  //         onClick={(e) => {
  //           e.stopPropagation();
  //           setOpen((val) => !val);
  //         }}
  //       >
  //         <span
  //           style={{
  //             marginRight: isExpanded ? "8.5px" : 0,
  //             flexBasis: "1rem",
  //           }}
  //         >
  //           {Icon ? <Icon /> : []}
  //         </span>
  //         {isExpanded && (
  //           <span
  //             className={[classes.title, !isExpanded ? "shrink" : ""].join(" ")}
  //           >
  //             <p style={{ margin: 0, width: "max-content" }}>{title}</p>
  //           </span>
  //         )}
  //         {kiddos && kiddos.length ? (
  //           <span
  //             style={{
  //               flexBasis: "1rem",
  //               display: "flex",
  //               alignItems: "center",
  //             }}
  //           >
  //             <BsChevronDown
  //               className={[classes.chevron, open ? "open" : ""].join(" ")}
  //             />
  //           </span>
  //         ) : (
  //           []
  //         )}
  //       </button>
  //     )}
  //     {kiddos && kiddos.length ? (
  //       <div
  //         className={[classes.kiddosContainer, open ? "open" : ""].join(" ")}
  //       >
  //         {kiddos.map(({ title, shrinkTitle, kiddoURL }, i) => {
  //           return (
  //             <Link
  //               to={{
  //                 pathname: kiddoURL,
  //               }}
  //               className={[
  //                 classes.aKiddo,
  //                 matchKiddo === kiddoURL ? "match" : "",
  //               ].join(" ")}
  //               key={`${i}-${title}`}
  //             >
  //               <span
  //                 className={[classes.title, !isExpanded ? "shrink" : ""].join(
  //                   " ",
  //                 )}
  //                 style={{
  //                   marginLeft: isExpanded ? "2rem" : 0,
  //                 }}
  //               >
  //                 <p
  //                   style={{
  //                     margin: isExpanded ? 0 : "auto",
  //                     width: "max-content",
  //                   }}
  //                 >
  //                   {isExpanded ? title : shrinkTitle}
  //                 </p>
  //               </span>
  //             </Link>
  //           );
  //         })}
  //       </div>
  //     ) : (
  //       []
  //     )}
  //   </div>
  // );
  return allowed ? (
    /**
     * @todo buatkan sidebar item ini menjadi sebuah `Link` atau `a`, sehingga menjadi benar secara semantik
     * sehingga ketika item ini diklik kanan, kita bisa buka di new tab.
     */
    <div className={[classes.container].join(" ")}>
      {!kiddos || kiddos.length === 0 ? (
        <Link
          to={{
            pathname: parentURL,
          }}
          className={[
            classes.buttonStyle,
            open ? "open" : undefined,
            match ? "match" : undefined,
            !isExpanded ? "shrink" : undefined,
          ].join(" ")}
          // onClick={(e) => {
          //   e.stopPropagation();
          //   setOpen((val) => !val);
          //   if (parentURL) {
          //     navigate(parentURL);
          //   }
          // }}
        >
          <span
            style={{
              marginRight: isExpanded ? "8.5px" : 0,
              flexBasis: "1rem",
            }}
          >
            {Icon ? <Icon /> : []}
          </span>
          {isExpanded && (
            <span
              className={[classes.title, !isExpanded ? "shrink" : ""].join(" ")}
            >
              <p style={{ margin: 0, width: "fit-content" }}>{title}</p>
            </span>
          )}
        </Link>
      ) : (
        <button
          className={[
            classes.buttonExpandStyle,
            open && "open",
            !isExpanded && "shrink",
          ].join(" ")}
          onClick={(e) => {
            e.stopPropagation();
            setOpen((val) => !val);
          }}
        >
          <span
            style={{
              marginRight: isExpanded ? "8.5px" : 0,
              flexBasis: "1rem",
            }}
          >
            {Icon ? <Icon /> : []}
          </span>
          {isExpanded && (
            <span
              className={[classes.title, !isExpanded ? "shrink" : ""].join(" ")}
            >
              <p style={{ margin: 0, width: "fit-content" }}>{title}</p>
            </span>
          )}
          {listedKiddos && listedKiddos.length ? (
            <span
              style={{
                flexBasis: "1rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <BsChevronDown
                className={[classes.chevron, open ? "open" : ""].join(" ")}
              />
            </span>
          ) : (
            []
          )}
        </button>
      )}
      {listedKiddos && listedKiddos.length ? (
        <div
          className={[classes.kiddosContainer, open ? "open" : ""].join(" ")}
        >
          {listedKiddos.map(({ title, shrinkTitle, kiddoURL }, i) => {
            return (
              <Link
                to={{
                  pathname: kiddoURL,
                }}
                className={[
                  classes.aKiddo,
                  matchKiddo === kiddoURL ? "match" : "",
                  !isExpanded ? "shrink" : "",
                ].join(" ")}
                key={`${i}-${title}`}
              >
                <span
                  className={[classes.title, !isExpanded ? "shrink" : ""].join(
                    " ",
                  )}
                  style={{
                    marginLeft: isExpanded ? "2rem" : 0,
                  }}
                >
                  <p
                    style={{
                      margin: isExpanded ? 0 : "auto",
                      width: "fit-content",
                    }}
                  >
                    {isExpanded ? title : shrinkTitle}
                  </p>
                </span>
              </Link>
            );
          })}
        </div>
      ) : (
        []
      )}
    </div>
  ) : (
    <></>
  );
};

export default SidebarItem;
