import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";

import {
  IPersonelChecklist,
  IPenilaianMandiriPersonel,
  IGetPersonelChecklistReqObj,
} from "types/endpoints/personel/checklist";

export const personelChecklistStoreAPI = createApi({
  reducerPath: "personelChecklistStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["PersonelChecklistList"],
  endpoints: (builder) => {
    return {
      getPersonelChecklist: builder.query<
        IPersonelChecklist[],
        Partial<IGetPersonelChecklistReqObj>
      >({
        query: ({ id }) => {
          let url = `/personel/${id}/checklists`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<IPersonelChecklist[]>,
        ) => {
          return response.data;
        },
        providesTags: (_, __, { id }) => [
          "PersonelChecklistList",
          { type: "PersonelChecklistList", id },
        ],
      }),
      getPersonelChecklistDetail: builder.query<
        IPersonelChecklist[],
        Partial<IGetPersonelChecklistReqObj>
      >({
        query: ({ id, checklistId }) => {
          let url = `/personel/${id}/checklists/${checklistId}`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<IPersonelChecklist[]>,
        ) => {
          return response.data;
        },
      }),
      createPersonelChecklist: builder.mutation<
        Pick<IPersonelChecklist, "id">[],
        {
          id: number;
          data: Partial<IPenilaianMandiriPersonel>;
        }
      >({
        query: ({ id, data }) => {
          return {
            url: `/personel/${id}/checklists`,
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["PersonelChecklistList"],
      }),
      deletePersonelChecklist: builder.mutation<
        BackendDataShape<object>,
        {
          id: number;
          checklistId: number;
        }
      >({
        query: ({ id, checklistId }) => {
          return {
            url: `/personel/${id}/checklists/${checklistId}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["PersonelChecklistList"],
      }),
    };
  },
});

export const {
  useGetPersonelChecklistQuery,
  useGetPersonelChecklistDetailQuery,
  useCreatePersonelChecklistMutation,
  useDeletePersonelChecklistMutation,
  util: { resetApiState: resetPersonelChecklistStoreAPI },
} = personelChecklistStoreAPI;
