import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "..";

import CookieMonster from "api/CookieMonster";
import { KnownUsertype, KnownUsertypeName } from "api/credentialsAPI/usertype";

import { ListOfRoutes } from "config/nav";

/**
 * This hook will check usertype in redux store first, if failed it will check Cookies.
 */
const useUsertype = () => {
  const fromSelector = useAppSelector(
    ({ userStore }) => userStore.userData?.usertype?.role,
  );
  const fromCookies = CookieMonster.loadCookie("usertypeRole") as
    | KnownUsertype
    | undefined;

  const usertype = fromSelector ?? fromCookies;
  // if (!usertype) {
  //   navigate(ListOfRoutes.loginpage, {
  //     state: {
  //       from: location.pathname,
  //     },
  //   });
  //   return "";
  // }
  return usertype;
};

export const useUsertypeName = () => {
  const fromSelector = useAppSelector(
    ({ userStore }) => userStore.userData?.usertype?.name,
  );
  const fromCookies = CookieMonster.loadCookie("usertypeName") as
    | KnownUsertypeName
    | undefined;

  const usertypeName = fromSelector ?? fromCookies;

  return usertypeName;
};

export const useUsertypePermission = () => {
  const usertype = useUsertype();
  const usertypeName = useUsertypeName();
  let usertypePermission: (KnownUsertype | KnownUsertypeName)[] = [];
  if (usertype) {
    usertypePermission.push(usertype);
  }
  if (usertypeName) {
    usertypePermission.push(usertypeName);
  }
  return usertypePermission;
};

/**
 *
 * @deprecated `navigate` inside this hook should be inside a `useEffect`
 */
export const useUsertypeWithSideEffect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const usertype = useUsertype();
  if (!usertype) {
    navigate(ListOfRoutes.loginpage, {
      state: {
        from: location.pathname,
      },
    });
    return "";
  }
  return usertype;
};

/**
 *
 * @param user the user permissions
 * @param list list of allowed permissions. if this is undefined or the list length is 1, user should be allowed
 * @returns whether the user is allowed or not
 */
export const checkIfUsertypeAllowed = (
  user: (KnownUsertype | KnownUsertypeName)[],
  list?: string[],
): boolean => {
  if (list && list.length) {
    let found = list.some((val) => {
      return user.length ? user.some((u) => u === val) : false;
      // return val === user[0] || val === user[1];
    });
    return !!found;
  } else {
    return true;
  }
};

export default useUsertype;
