import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";
import { convertToEncodedURL } from "assets/usefulFunctions";

import {
  IGetDashboardReqObj,
  IDashboardTotalPesudAccident,
  IDashboardMaxPesudAccident,
  IDashboardPesudCondition,
  IDashboardFactorAccident,
} from "types/endpoints/dashboard/dashboard";

export const dashboardStoreAPI = createApi({
  reducerPath: "dashboardStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: [
    "DashboardTotalPesudAccidentList",
    "DashboardMaxPesudAccidentList",
    "DashboardPesudConditionList",
    "DashboardFactorAccidentList",
  ],
  endpoints: (builder) => {
    return {
      getDashboardTotalPesudAccident: builder.query<
        IDashboardTotalPesudAccident[],
        Partial<IGetDashboardReqObj>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/dashboard/total-pesud-accident" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<IDashboardTotalPesudAccident[]>,
        ) => {
          return response.data;
        },
        providesTags: ["DashboardTotalPesudAccidentList"],
      }),
      getDashboardMaxPesudAccident: builder.query<
        IDashboardMaxPesudAccident[],
        Partial<IGetDashboardReqObj>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/dashboard/max-pesud-accident" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<IDashboardMaxPesudAccident[]>,
        ) => {
          return response.data;
        },
        providesTags: ["DashboardMaxPesudAccidentList"],
      }),
      getDashboardPesudCondition: builder.query<
        IDashboardPesudCondition,
        Partial<IGetDashboardReqObj>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/dashboard/pesud-condition" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<IDashboardPesudCondition>,
        ) => {
          return response.data;
        },
        providesTags: ["DashboardPesudConditionList"],
      }),
      getDashboardFactorAccident: builder.query<
        IDashboardFactorAccident,
        Partial<IGetDashboardReqObj>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/dashboard/factor-accident" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<IDashboardFactorAccident>,
        ) => {
          return response.data;
        },
        providesTags: ["DashboardFactorAccidentList"],
      }),
    };
  },
});

export const {
  useGetDashboardTotalPesudAccidentQuery,
  useGetDashboardMaxPesudAccidentQuery,
  useGetDashboardPesudConditionQuery,
  useGetDashboardFactorAccidentQuery,
  util: { resetApiState: resetDashboardStoreAPI },
} = dashboardStoreAPI;
