import { UsedAPI } from "api/config";

export const ListOfRoutes = {
  homepage: "/",
  loginpage: "/login",
  dashboard: "/dashboard",
  management: "/management",
} as const;

export const ReportLinks = {
  filter_by_caseLog: (params: string) => `${UsedAPI}/case${params}`,
} as const;

export const DashboardPuspenerbalLinks = {
  get dashboard_by_dsp(): string {
    return `${UsedAPI}/dashboard/rekap_dsp`;
  },

  get dashboard_by_aktifitas_penerbangan(): string {
    return `${UsedAPI}/dashboard/aktivitas_penerbangan`;
  },
} as const;

export class SisfoK3Routes {
  static get base(): string {
    return `/` as const;
  }

  static get login(): string {
    return `${this.base}login` as const;
  }

  static get logout(): string {
    return `${this.base}logout` as const;
  }

  static get account(): string {
    return `${this.base}account` as const;
  }

  static get account_change_password(): string {
    return `${this.account}/change-password` as const;
  }

  static get account_generate_otp(): string {
    return `${this.account}/otp` as const;
  }

  static get notification(): string {
    return `${this.base}notification` as const;
  }

  static get dashboard(): string {
    return `${this.base}dashboard` as const;
  }

  static get dashboard_overview(): string {
    return `${this.dashboard}/overview` as const;
  }

  static get master(): string {
    return `${this.base}master` as const;
  }

  static get master_penilaian_teknis(): string {
    return `${this.master}/penilaian-teknis` as const;
  }

  static updateSpecificMasterPenilaianTeknis(id?: string): string {
    return `${this.master}/penilaian-teknis/edit/${id}` as const;
  }

  static get master_standarisasi_penilaian(): string {
    return `${this.master}/standarisasi-penilaian` as const;
  }

  static get management(): string {
    return `${this.base}management` as const;
  }

  static get management_superadmin(): string {
    return `${this.management}/superadmin` as const;
  }

  static get management_account(): string {
    return `${this.management_superadmin}/account` as const;
  }

  static get management_workflow(): string {
    return `${this.management_superadmin}/workflow` as const;
  }

  static get management_pemeriksaan(): string {
    return `${this.management}/pemeriksaan` as const;
  }

  static get management_penilaian_teknis(): string {
    return `${this.management}/penilaian-teknis` as const;
  }

  static get management_visual_analisis(): string {
    return `${this.management}/visual-analisis` as const;
  }

  static updateSpecificManagementPenilaianTeknis(id?: string): string {
    return `${this.management_penilaian_teknis}/edit/${id}` as const;
  }

  static getSpecificManagementPenilaianTeknis(id?: string): string {
    return `${this.management_penilaian_teknis}/view/${id}` as const;
  }

  static get management_operasional(): string {
    return `${this.management}/operasional` as const;
  }

  static getSpecificManagementOperasional(id?: string): string {
    return `${this.management_operasional}/view/${id}` as const;
  }

  static get management_hira(): string {
    return `${this.management}/hira` as const;
  }

  static get penilaian(): string {
    return `${this.base}penilaian` as const;
  }

  static get penilaian_kesiapan_personel(): string {
    return `${this.penilaian}/kesiapan-personel` as const;
  }

  static get penilaian_stakes_personel(): string {
    return `${this.penilaian}/stakes-personel` as const;
  }

  static get penilaian_garjas_personel(): string {
    return `${this.penilaian}/garjas-personel` as const;
  }

  static get penilaian_mandiri_personel(): string {
    return `${this.penilaian}/mandiri-personel` as const;
  }

  static get penilaian_kesiapan_kelaikan_pesawat(): string {
    return `${this.penilaian}/kesiapan-kelaikan-pesawat` as const;
  }

  static get assessment(): string {
    return `${this.base}assessment` as const;
  }

  static get news(): string {
    return `${this.base}news` as const;
  }

  static get report(): string {
    return `${this.base}laporan` as const;
  }

  static get backup_restore(): string {
    return `${this.base}backup-restore` as const;
  }

  static get backup(): string {
    return `${this.backup_restore}/backup` as const;
  }

  static get restore(): string {
    return `${this.backup_restore}/restore` as const;
  }
}
