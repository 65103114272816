import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";

import { convertToEncodedURL } from "assets/usefulFunctions";

import { NewBackendDataShape, PaginationRequestObj } from "types";
import { Asset } from "types/endpoints/dailycheckup/dailycheckup";

export const assetStoreAPI = createApi({
  reducerPath: "assetStore",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/asset`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Assets"],
  endpoints: (builder) => {
    return {
      getAssets: builder.query<Asset[], PaginationRequestObj>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          return {
            url: params,
            method: "GET",
          };
        },
        providesTags: ["Assets"],
        transformResponse: (res: NewBackendDataShape<Asset[]>) => {
          return res.data;
        },
      }),
    };
  },
});

export const {
  useGetAssetsQuery,
  util: { resetApiState: resetAssetsStore },
} = assetStoreAPI;
