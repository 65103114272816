import { lazy, Suspense } from "react";
import {
  MdDashboard,
  MdOutlineAccountCircle,
  MdOutlineBackup,
  MdOutlineDescription,
  MdOutlineLogout,
  MdViewList,
} from "react-icons/md";
import { GoChecklist } from "react-icons/go";
import { HashRouter, Route, Routes, Outlet, Navigate } from "react-router-dom";

import { COLORS } from "assets/theme";

import { SisfoK3Routes } from "config/nav";

import LoaderPage from "components/atoms/Loader/LoaderPage/LoaderPage";
import AuthHOC from "components/auth/AuthHOC";
import UserGate from "components/auth/UserGate";
import { SidebarItemProps } from "components/Sidebar/SidebarItem";
import { StyledToastContainerPuspenerbal } from "components/Toast/StyledToastContainerPuspenerbal";

import Layout from "layout/Layout";

import NoMatch from "pages/NoMatch";
import Redirector from "pages/Redirector";

const LoginPage = lazy(() => import("pages/LoginPage"));

const DashboardPage = lazy(() => import("pages/Dashboard"));

const ReportPage = lazy(() => import("pages/Report"));

const BackupRestorePage = lazy(() => import("pages/BackupRestore"));

const AccountViewPage = lazy(() => import("pages/Superadmin/AccountView"));

const WorkflowPage = lazy(() => import("pages/Superadmin/Workflow"));

const MasterPenilaianTeknisPage = lazy(
  () => import("pages/Master/PenilaianTeknis"),
);

const MasterStandarisasiPenilaianPage = lazy(
  () => import("pages/Master/StandarisasiPenilaian"),
);

const MasterUpdatePenilaianTeknisPage = lazy(
  () => import("pages/Master/PenilaianTeknis/UpdatePenilaianTeknisPage"),
);

// const ManagementPemeriksaanPage = lazy(
//   () =>
//     import("pages/Management/ManagementPemeriksaan/ManagementPemeriksaanPage"),
// );

const ManagementPenilaianTeknisPage = lazy(
  () => import("pages/Management/PenilaianTeknis"),
);

const ManagementAnalisisVisualPage = lazy(
  () => import("pages/Management/AnalisisVisual"),
);
const ManagementUpdatePenilaianTeknisPage = lazy(
  () => import("pages/Management/PenilaianTeknis/UpdatePenilaianTeknisPage"),
);

const ManagementViewPenilaianTeknisPage = lazy(
  () => import("pages/Management/PenilaianTeknis/ViewPenilaianTeknisPage"),
);

const ManagementOperasionalPage = lazy(
  () => import("pages/Management/Operasional"),
);

const ManagementViewOperasionalPage = lazy(
  () => import("pages/Management/Operasional/ViewOperasionalPage"),
);

const ManagementHIRAPage = lazy(() => import("pages/Management/HIRA"));

const PenilaianMandiriK3PersonelPage = lazy(
  () => import("pages/Personel/PenilaianMandiriK3Personel"),
);

const PenilaianKesiapanPersonelPage = lazy(
  () => import("pages/Penilaian/KesiapanPersonel"),
);

const PenilaianStakesPersonelPage = lazy(
  () => import("pages/Penilaian/StakesPersonel"),
);

const PenilaianGarjasPersonelPage = lazy(
  () => import("pages/Penilaian/GarjasPersonel"),
);

const PenilaianMandiriPersonel = lazy(
  () => import("pages/Penilaian/MandiriPersonel"),
);

// const PenilaianPesawatUdara = lazy(
//   () => import("pages/Penilaian/PesawatUdara"),
// );

const NotificationPage = lazy(
  () => import("pages/NotificationPage/NotificationPage"),
);

const NewsPage = lazy(() => import("pages/News"));

const ChangePasswordPage = lazy(
  () => import("pages/AccountPage/ChangePasswordPage"),
);

const GenerateOtpPage = lazy(() => import("pages/AccountPage/GenerateOtpPage"));

const LogoutPage = lazy(() => import("pages/Logout"));

const basicNav: SidebarItemProps[] = [
  {
    title: "Dashboard",
    parentURL: SisfoK3Routes.dashboard_overview,
    Icon: () => (
      <MdDashboard size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    allowedUsers: ["kasi", "kasubsi", "kasubdit"],
  },
  {
    title: "Master",
    parentURL: SisfoK3Routes.master,
    Icon: () => (
      <MdViewList size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    kiddos: [
      {
        title: "Penilaian Teknis",
        shrinkTitle: "PT",
        kiddoURL: SisfoK3Routes.master_penilaian_teknis,
        allowedUsers: ["kasi", "kasubsi", "kasubdit"],
      },
      {
        title: "Standarisasi Penilaian",
        shrinkTitle: "SP",
        kiddoURL: SisfoK3Routes.master_standarisasi_penilaian,
        allowedUsers: ["kasi", "kasubsi", "kasubdit"],
      },
    ],
    allowedUsers: ["kasi", "kasubsi", "kasubdit"],
  },
  {
    title: "Manajemen",
    parentURL: SisfoK3Routes.management,
    Icon: () => (
      <MdViewList size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    kiddos: [
      {
        title: "Akun",
        shrinkTitle: "AK",
        kiddoURL: SisfoK3Routes.management_account,
        allowedUsers: ["superadmin"],
      },
      {
        title: "Alur Kerja",
        shrinkTitle: "AL",
        kiddoURL: SisfoK3Routes.management_workflow,
        allowedUsers: ["superadmin"],
      },
      // {
      //   title: "Pemeriksaan",
      //   shrinkTitle: "PM",
      //   kiddoURL: SisfoK3Routes.management_pemeriksaan,
      //   allowedUsers: ["kasi", "kasubsi", "kasubdit"],
      // },
      {
        title: "Penilaian Teknis",
        shrinkTitle: "PT",
        kiddoURL: SisfoK3Routes.management_penilaian_teknis,
        allowedUsers: ["kasi", "kasubsi", "kasubdit"],
      },
      {
        title: "Analisa Visual",
        shrinkTitle: "AV",
        kiddoURL: SisfoK3Routes.management_visual_analisis,
        allowedUsers: ["kasi", "kasubsi", "kasubdit"],
      },
      {
        title: "Operasional",
        shrinkTitle: "OP",
        kiddoURL: SisfoK3Routes.management_operasional,
        allowedUsers: ["kasi", "kasubsi", "kasubdit"],
      },
      {
        title: "HIRA",
        shrinkTitle: "HR",
        kiddoURL: SisfoK3Routes.management_hira,
        allowedUsers: ["kasi", "kasubsi", "kasubdit"],
      },
    ],
    allowedUsers: ["kasi", "kasubsi", "kasubdit", "superadmin"],
  },
  {
    title: "Penilaian",
    parentURL: SisfoK3Routes.penilaian,
    Icon: () => (
      <MdOutlineDescription
        size={18}
        color={COLORS.white}
        fill={COLORS.white}
      />
    ),
    kiddos: [
      {
        title: "Kesiapan Personel",
        shrinkTitle: "KP",
        kiddoURL: SisfoK3Routes.penilaian_kesiapan_personel,
        allowedUsers: ["kasi", "kasubsi", "kasubdit"],
      },
      {
        title: "Stakes Personel",
        shrinkTitle: "SP",
        kiddoURL: SisfoK3Routes.penilaian_stakes_personel,
        allowedUsers: ["kasi", "kasubsi", "kasubdit"],
      },
      {
        title: "Garjas Personel",
        shrinkTitle: "GP",
        kiddoURL: SisfoK3Routes.penilaian_garjas_personel,
        allowedUsers: ["kasi", "kasubsi", "kasubdit"],
      },
      {
        title: "Mandiri Personel",
        shrinkTitle: "MP",
        kiddoURL: SisfoK3Routes.penilaian_mandiri_personel,
        allowedUsers: ["kasi", "kasubsi", "kasubdit"],
      },
      // {
      //   title: "Pesawat Udara",
      //   shrinkTitle: "PU",
      //   kiddoURL: SisfoK3Routes.penilaian_kesiapan_kelaikan_pesawat,
      //   allowedUsers: ["kasi", "kasubsi", "kasubdit"],
      // },
    ],
    allowedUsers: ["kasi", "kasubsi", "kasubdit"],
  },
  {
    title: "Assessment Personel",
    parentURL: SisfoK3Routes.assessment,
    Icon: () => (
      <GoChecklist size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    allowedUsers: ["user"],
  },
  {
    title: "Berita",
    parentURL: SisfoK3Routes.news,
    Icon: () => (
      <MdViewList size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    allowedUsers: ["kasi", "kasubsi", "kasubdit"],
  },
  {
    title: "Pencadangan",
    parentURL: SisfoK3Routes.backup_restore,
    Icon: () => (
      <MdOutlineBackup size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    kiddos: [
      {
        title: "Backup",
        shrinkTitle: "BK",
        kiddoURL: SisfoK3Routes.backup,
        allowedUsers: ["superadmin"],
      },
      {
        title: "Restore",
        shrinkTitle: "RS",
        kiddoURL: SisfoK3Routes.restore,
        allowedUsers: ["superadmin"],
      },
    ],
    allowedUsers: ["superadmin"],
  },
  {
    Icon: () => (
      <MdOutlineDescription
        size={18}
        color={COLORS.white}
        fill={COLORS.white}
      />
    ),
    parentURL: SisfoK3Routes.report,
    title: "Laporan",
    allowedUsers: ["kasi", "kasubsi", "kasubdit"],
  },
  {
    Icon: () => (
      <MdOutlineAccountCircle
        size={18}
        color={COLORS.white}
        fill={COLORS.white}
      />
    ),
    parentURL: SisfoK3Routes.account,
    title: "Akun",
    kiddos: [
      {
        title: "Ubah Password",
        shrinkTitle: "UP",
        kiddoURL: SisfoK3Routes.account_change_password,
      },
      {
        title: "GENERATE OTP",
        shrinkTitle: "GO",
        kiddoURL: SisfoK3Routes.account_generate_otp,
      },
    ],
  },
  {
    title: "Keluar",
    parentURL: SisfoK3Routes.logout,
    Icon: () => (
      <MdOutlineLogout size={18} color={COLORS.white} fill={COLORS.white} />
    ),
  },
];

function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route
            path="/"
            element={
              <AuthHOC>
                <Outlet />
              </AuthHOC>
            }
          >
            <Route index element={<Navigate to={`${SisfoK3Routes.login}`} />} />

            <Route
              path={SisfoK3Routes.base}
              element={
                <Layout sidebarItems={basicNav}>
                  <Outlet />
                </Layout>
              }
            >
              <Route index element={<Redirector />} />
              <Route
                path={`${SisfoK3Routes.dashboard_overview}`}
                element={
                  <UserGate allowedUsers={["kasi", "kasubsi", "kasubdit"]}>
                    <DashboardPage />
                  </UserGate>
                }
              />

              <Route
                path={`${SisfoK3Routes.master_penilaian_teknis}`}
                element={
                  <UserGate allowedUsers={["kasi", "kasubsi", "kasubdit"]}>
                    <MasterPenilaianTeknisPage />
                  </UserGate>
                }
              />

              <Route
                path={`${SisfoK3Routes.updateSpecificMasterPenilaianTeknis(
                  ":id",
                )}`}
                element={
                  <UserGate allowedUsers={["kasi", "kasubsi", "kasubdit"]}>
                    <MasterUpdatePenilaianTeknisPage />
                  </UserGate>
                }
              />

              <Route
                path={`${SisfoK3Routes.master_standarisasi_penilaian}`}
                element={
                  <UserGate allowedUsers={["kasi", "kasubsi", "kasubdit"]}>
                    <MasterStandarisasiPenilaianPage />
                  </UserGate>
                }
              />

              <Route
                path={SisfoK3Routes.report}
                element={
                  <UserGate allowedUsers={["kasi", "kasubsi", "kasubdit"]}>
                    <ReportPage />
                  </UserGate>
                }
              />

              <Route
                path={SisfoK3Routes.management_account}
                element={
                  <UserGate allowedUsers={["superadmin"]}>
                    <AccountViewPage />
                  </UserGate>
                }
              />
              <Route
                path={SisfoK3Routes.management_workflow}
                element={
                  <UserGate allowedUsers={["superadmin"]}>
                    <WorkflowPage />
                  </UserGate>
                }
              />

              {/* <Route
                path={`${SisfoK3Routes.management_pemeriksaan}`}
                element={
                  <UserGate allowedUsers={["kasi", "kasubsi", "kasubdit"]}>
                    <ManagementPemeriksaanPage />
                  </UserGate>
                }
              /> */}

              <Route
                path={`${SisfoK3Routes.management_penilaian_teknis}`}
                element={
                  <UserGate allowedUsers={["kasi", "kasubsi", "kasubdit"]}>
                    <ManagementPenilaianTeknisPage />
                  </UserGate>
                }
              />

              <Route
                path={`${SisfoK3Routes.management_visual_analisis}`}
                element={
                  <UserGate allowedUsers={["kasi", "kasubsi", "kasubdit"]}>
                    <ManagementAnalisisVisualPage />
                  </UserGate>
                }
              />

              <Route
                path={`${SisfoK3Routes.updateSpecificManagementPenilaianTeknis(
                  ":id",
                )}`}
                element={
                  <UserGate allowedUsers={["kasi", "kasubsi", "kasubdit"]}>
                    <ManagementUpdatePenilaianTeknisPage />
                  </UserGate>
                }
              />

              <Route
                path={`${SisfoK3Routes.getSpecificManagementPenilaianTeknis(
                  ":id",
                )}`}
                element={
                  <UserGate allowedUsers={["kasi", "kasubsi", "kasubdit"]}>
                    <ManagementViewPenilaianTeknisPage />
                  </UserGate>
                }
              />

              <Route
                path={`${SisfoK3Routes.management_operasional}`}
                element={
                  <UserGate allowedUsers={["kasi", "kasubsi", "kasubdit"]}>
                    <ManagementOperasionalPage />
                  </UserGate>
                }
              />

              <Route
                path={`${SisfoK3Routes.getSpecificManagementOperasional(
                  ":id",
                )}`}
                element={
                  <UserGate allowedUsers={["kasi", "kasubsi", "kasubdit"]}>
                    <ManagementViewOperasionalPage />
                  </UserGate>
                }
              />

              <Route
                path={`${SisfoK3Routes.management_hira}`}
                element={
                  <UserGate allowedUsers={["kasi", "kasubsi", "kasubdit"]}>
                    <ManagementHIRAPage />
                  </UserGate>
                }
              />

              <Route
                path={`${SisfoK3Routes.penilaian_kesiapan_personel}`}
                element={
                  <UserGate allowedUsers={["kasi", "kasubsi", "kasubdit"]}>
                    <PenilaianKesiapanPersonelPage />
                  </UserGate>
                }
              />

              <Route
                path={`${SisfoK3Routes.penilaian_stakes_personel}`}
                element={
                  <UserGate allowedUsers={["kasi", "kasubsi", "kasubdit"]}>
                    <PenilaianStakesPersonelPage />
                  </UserGate>
                }
              />

              <Route
                path={`${SisfoK3Routes.penilaian_garjas_personel}`}
                element={
                  <UserGate allowedUsers={["kasi", "kasubsi", "kasubdit"]}>
                    <PenilaianGarjasPersonelPage />
                  </UserGate>
                }
              />

              <Route
                path={`${SisfoK3Routes.penilaian_mandiri_personel}`}
                element={
                  <UserGate allowedUsers={["kasi", "kasubsi", "kasubdit"]}>
                    <PenilaianMandiriPersonel />
                  </UserGate>
                }
              />

              {/* <Route
                path={`${SisfoK3Routes.penilaian_kesiapan_kelaikan_pesawat}`}
                element={
                  <UserGate allowedUsers={["kasi", "kasubsi", "kasubdit"]}>
                    <PenilaianPesawatUdara />
                  </UserGate>
                }
              /> */}

              <Route
                path={SisfoK3Routes.assessment}
                element={
                  <UserGate allowedUsers={["user"]}>
                    <PenilaianMandiriK3PersonelPage />
                  </UserGate>
                }
              />

              <Route
                path={SisfoK3Routes.notification}
                element={<NotificationPage />}
              />

              <Route
                path={SisfoK3Routes.news}
                element={
                  <UserGate allowedUsers={["kasi", "kasubsi", "kasubdit"]}>
                    <NewsPage />
                  </UserGate>
                }
              />

              <Route
                path={`${SisfoK3Routes.backup_restore}/:type`}
                element={
                  <UserGate allowedUsers={["superadmin"]}>
                    <BackupRestorePage />
                  </UserGate>
                }
              />

              <Route
                path={SisfoK3Routes.account_change_password}
                element={<ChangePasswordPage />}
              />

              <Route
                path={SisfoK3Routes.account_generate_otp}
                element={<GenerateOtpPage />}
              />

              <Route path={SisfoK3Routes.logout} element={<LogoutPage />} />
            </Route>
          </Route>
          <Route
            path={SisfoK3Routes.login}
            element={
              <Suspense fallback={<LoaderPage />}>
                <Outlet />
              </Suspense>
            }
          >
            <Route index element={<LoginPage />} />
          </Route>

          <Route path="*" element={<NoMatch />} />
        </Routes>
      </HashRouter>
      <StyledToastContainerPuspenerbal />
    </>
  );
}

export default App;
