import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";
import { convertToEncodedURL } from "assets/usefulFunctions";
import {
  APaginationEntity,
  BackendDataPageShape,
  BackendPaginationRequestObject,
} from "types";
import {
  IGetHIRAReqObj,
  IGetRiskIndexReqObj,
  IHIRA,
  ILogHIRA,
  IRiskIndex,
  TResultFormInput,
  TTolerableFormInput,
} from "types/endpoints/HIRA";

export const HIRAStoreAPI = createApi({
  reducerPath: "HIRAStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["RiskProbabilityList", "RiskSeverityList", "HIRAList"],
  endpoints: (builder) => {
    return {
      getRiskProbability: builder.query<string[], object>({
        query: () => {
          let url = "/hira/risk-probability";
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<string[]>) =>
          response.data,
        providesTags: ["RiskProbabilityList"],
      }),
      getRiskSeverity: builder.query<string[], object>({
        query: () => {
          let url = "/hira/risk-severity";
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<string[]>) =>
          response.data,
        providesTags: ["RiskSeverityList"],
      }),
      getRiskIndex: builder.query<IRiskIndex, Partial<IGetRiskIndexReqObj>>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/hira/risk-index" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<IRiskIndex>) =>
          response.data,
      }),
      getHIRAPagination: builder.query<
        APaginationEntity<IHIRA[]>,
        BackendPaginationRequestObject<Partial<IGetHIRAReqObj>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/hira" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<IHIRA[]>) =>
          response.data,
        providesTags: ["HIRAList"],
      }),
      createHIRA: builder.mutation<Pick<IHIRA, "id">[], Partial<IHIRA>>({
        query: (data) => {
          return {
            url: "/hira",
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["HIRAList"],
      }),
      getHIRAStatusById: builder.query<string[], { id: number }>({
        query: ({ id }) => {
          let url = `/hira/${id}/status`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<string[]>) =>
          response.data,
        providesTags: (_, __, { id }) => ["HIRAList", { type: "HIRAList", id }],
      }),
      getHIRAById: builder.query<ILogHIRA[], { id: number }>({
        query: ({ id }) => {
          let url = `/hira/${id}`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<ILogHIRA[]>) =>
          response.data,
        providesTags: ["HIRAList"],
      }),
      createMitigasiSeverity: builder.mutation<
        Pick<IHIRA, "id">[],
        {
          id: number;
          data: Partial<IHIRA>;
        }
      >({
        query: ({ id, data }) => {
          return {
            url: `/hira/${id}/mitigation`,
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["HIRAList"],
      }),
      updateTolerable: builder.mutation<
        Pick<IHIRA, "id">[],
        {
          id: number;
          data: Partial<TTolerableFormInput>;
        }
      >({
        query: ({ id, data }) => {
          return {
            url: `/hira/${id}/validate`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["HIRAList"],
      }),
      updateResult: builder.mutation<
        Pick<IHIRA, "id">[],
        {
          id: number;
          data: Partial<TResultFormInput>;
        }
      >({
        query: ({ id, data }) => {
          return {
            url: `/hira/${id}/finish`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["HIRAList"],
      }),
    };
  },
});

export const {
  useGetRiskProbabilityQuery,
  useGetRiskSeverityQuery,
  useGetRiskIndexQuery,
  useGetHIRAPaginationQuery,
  useCreateHIRAMutation,
  useGetHIRAStatusByIdQuery,
  useGetHIRAByIdQuery,
  useCreateMitigasiSeverityMutation,
  useUpdateTolerableMutation,
  useUpdateResultMutation,
  util: { resetApiState: resetHIRAStoreAPI },
} = HIRAStoreAPI;
