import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";

import { convertToEncodedURL, jsonToFormData } from "assets/usefulFunctions";

import {
  APaginationEntity,
  BackendDataPageShape,
  BackendPaginationRequestObject,
} from "types";
import {
  IOperasional,
  IGetOperasionalReqObj,
  IGetCausalFactorChart,
  IGetOperasionalChartReqObj,
  IGetDescriptionChart,
  IGetCategoryPesudChart,
} from "types/endpoints/operasional";

export const operasionalStoreAPI = createApi({
  reducerPath: "operasionalStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: [
    "OperasionalList",
    "CausalFactorChart",
    "DescriptionChart",
    "CategoryPesudChart",
  ],
  endpoints: (builder) => {
    return {
      restoreOperasional: builder.mutation<
        object,
        {
          file: File[];
        }
      >({
        query: (data) => {
          const formData = jsonToFormData(data);
          return {
            url: "/accidents/restore",
            body: formData,
            method: "POST",
          };
        },
        invalidatesTags: ["OperasionalList"],
      }),
      getOperasionalPagination: builder.query<
        APaginationEntity<IOperasional[]>,
        BackendPaginationRequestObject<Partial<IGetOperasionalReqObj>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/accidents" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<IOperasional[]>) =>
          response.data,
        providesTags: ["OperasionalList"],
      }),
      getOperasionalDetail: builder.query<IOperasional, { id: number }>({
        query: ({ id }) => {
          let url = `/accidents/${id}`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<IOperasional>) =>
          response.data,
        providesTags: (_, __, { id }) => [
          "OperasionalList",
          { type: "OperasionalList", id },
        ],
      }),
      createOperasional: builder.mutation<
        Pick<IOperasional, "id">[],
        Partial<IOperasional>
      >({
        query: (data) => {
          return {
            url: "/accidents",
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["OperasionalList"],
      }),
      updateOperasional: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<IOperasional>;
        }
      >({
        query: ({ id, data }) => {
          return {
            url: `/accidents/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["OperasionalList"],
      }),
      deleteOperasional: builder.mutation<
        BackendDataShape<object>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/accidents/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["OperasionalList"],
      }),
      getCausalFactorChart: builder.query<
        IGetCausalFactorChart[],
        Partial<IGetOperasionalChartReqObj>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = `/accidents/causal-factor-chart` + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<IGetCausalFactorChart[]>,
        ) => response.data,
        providesTags: ["CausalFactorChart"],
      }),
      getDescriptionChart: builder.query<
        IGetDescriptionChart[],
        Partial<IGetOperasionalChartReqObj>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = `/accidents/description-chart` + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<IGetDescriptionChart[]>,
        ) => response.data,
        providesTags: ["DescriptionChart"],
      }),
      getCategoryPesudChart: builder.query<
        IGetCategoryPesudChart[],
        Partial<IGetOperasionalChartReqObj>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = `/accidents/pesud-category-chart` + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<IGetCategoryPesudChart[]>,
        ) => response.data,
        providesTags: ["CategoryPesudChart"],
      }),
    };
  },
});

export const {
  useRestoreOperasionalMutation,
  useGetOperasionalPaginationQuery,
  useGetOperasionalDetailQuery,
  useCreateOperasionalMutation,
  useDeleteOperasionalMutation,
  useUpdateOperasionalMutation,
  useGetCausalFactorChartQuery,
  useGetDescriptionChartQuery,
  useGetCategoryPesudChartQuery,
  util: { resetApiState: resetOperasionalStoreAPI },
} = operasionalStoreAPI;
