import { FunctionComponent, useMemo } from "react";
import { Navigate } from "react-router-dom";

import { KnownUsertypeName } from "api/credentialsAPI/usertype";

import { checkIfUndefined } from "assets/usefulFunctions";

import { SisfoK3Routes } from "config/nav";

import { useUsertypeName } from "hooks/auth/useUsertype";

interface Props {}

const Redirector: FunctionComponent<Props> = () => {
  const usertypeName = useUsertypeName();
  const to = getDefaultRoute(usertypeName);

  if (checkIfUndefined(usertypeName)) {
    return <>Loading...</>;
  }
  return <Navigate replace to={`${to}`} />;
};

export default Redirector;

export const getDefaultRoute = (usertypeLogin?: KnownUsertypeName) => {
  switch (usertypeLogin) {
    case "personel":
      return SisfoK3Routes.assessment;
    case "superadmin":
      return SisfoK3Routes.management_account;
    case "kasubsiJasrek":
    case "kasiWatpers":
    case "kasiBintal":
    case "kasiKum":
    case "kasubsiEvadal":
    case "kasiProfesi":
    case "kasubditWatpers":
    case "kasubsiUrikes":
    case "kasiKesud":
    case "kasiMinkes":
    case "kasubditKes":
    case "kasubsiDalpersmil":
    case "kasiDalpersmil":
    case "kasiDalperssip":
    case "kasubsiDatapers":
    case "kasiMindik":
    case "kasubditMinpers":
    default:
      return SisfoK3Routes.dashboard_overview;
  }
};

export const useDefaultRoute = () => {
  const usertypeName = useUsertypeName();
  return useMemo(() => {
    return getDefaultRoute(usertypeName);
  }, [usertypeName]);
};
