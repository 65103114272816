export type ACSVColumn<D extends object> = {
  [key in keyof D]: D[key];
};

export type ACSVHeader<D extends object> = {
  title: string;
  accessor: keyof D;
};

export interface CSVProps<D extends object> {
  headers: ACSVHeader<D>[];
  columns: ACSVColumn<D>[];
}

/**
 * @description Fungsi ini memisahkan kolom tabel dengan menggunakan
 * titik koma (semicolon)
 * @param "this param contains object CSVProps"
 * @returns string
 */
const createCSVFile = <D extends object>({
  headers,
  columns,
}: CSVProps<D>): string => {
  let csvText = "data:text/csv;charset=utf-8,";
  let head: string[] = [];
  headers.forEach(({ title }) => {
    head.push(title);
  });
  csvText += head.join(";") + "\r\n";

  columns.forEach((item) => {
    let col: D[keyof D][] = [];
    headers.forEach(({ accessor }) => {
      col.push(item[accessor]);
    });
    csvText += col.join(";") + "\r\n";
  });

  return encodeURI(csvText);
};

export default createCSVFile;
