import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";

import { convertToEncodedURL } from "assets/usefulFunctions";

import {
  APaginationEntity,
  BackendDataPageShape,
  BackendPaginationRequestObject,
} from "types";
import {
  IGetPersonelReqObj,
  APersonelObj,
  IPersonel,
  IPersonelAssessment,
  IPersonelCurrentAssessment,
  IPersonelGarjas,
  IPersonelStakes,
} from "types/endpoints/personel/personel";

export const personelStoreAPI = createApi({
  reducerPath: "personelStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: [
    "PersonelList",
    "PersonelAssessmentList",
    "PersonelGarjasList",
    "PersonelStakesList",
    "PersonelCurrentAssessmentList",
  ],
  endpoints: (builder) => {
    return {
      getPersonel: builder.query<IPersonel[], APersonelObj>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/personel/find" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<IPersonel[]>) =>
          response.data,
        providesTags: ["PersonelList"],
      }),
      getPersonelPagination: builder.query<
        APaginationEntity<IPersonel[]>,
        BackendPaginationRequestObject<Partial<IGetPersonelReqObj>>
      >({
        query: ({ ids, ...rest }) => {
          let params = "";
          if (rest) {
            params = convertToEncodedURL(rest);
            params = params.length ? "?" + params : "";
          }

          let idsparams = "";
          if (ids) {
            let idsObj = ids.map((item) => {
              return {
                ids: item,
              };
            });

            idsparams = idsObj
              .map((item) => convertToEncodedURL(item))
              .join("&");
            idsparams = params.length
              ? "&" + idsparams
              : idsparams.length
              ? "?" + idsparams
              : "";
          }
          let url = "/personel" + params + idsparams;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<IPersonel[]>) =>
          response.data,
        providesTags: ["PersonelList"],
      }),
      getPersonelAssessmentPagination: builder.query<
        APaginationEntity<IPersonelAssessment[]>,
        BackendPaginationRequestObject<Partial<IGetPersonelReqObj>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/personel/assessment" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataPageShape<IPersonelAssessment[]>,
        ) => response.data,
        providesTags: ["PersonelAssessmentList"],
      }),
      getPersonelGarjasPagination: builder.query<
        APaginationEntity<IPersonelGarjas[]>,
        BackendPaginationRequestObject<Partial<IGetPersonelReqObj>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/personel/garjas" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataPageShape<IPersonelGarjas[]>,
        ) => response.data,
        providesTags: ["PersonelGarjasList"],
      }),
      getPersonelStakesPagination: builder.query<
        APaginationEntity<IPersonelStakes[]>,
        BackendPaginationRequestObject<Partial<IGetPersonelReqObj>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/personel/stakes" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataPageShape<IPersonelStakes[]>,
        ) => response.data,
        providesTags: ["PersonelStakesList"],
      }),
      getPersonelCurrentAssessmentPagination: builder.query<
        APaginationEntity<IPersonelCurrentAssessment[]>,
        BackendPaginationRequestObject<Partial<IGetPersonelReqObj>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/personel/current-assessment" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataPageShape<IPersonelCurrentAssessment[]>,
        ) => response.data,
        providesTags: ["PersonelCurrentAssessmentList"],
      }),
    };
  },
});

export const {
  useGetPersonelQuery,
  useGetPersonelPaginationQuery,
  useGetPersonelAssessmentPaginationQuery,
  useGetPersonelStakesPaginationQuery,
  useGetPersonelGarjasPaginationQuery,
  useGetPersonelCurrentAssessmentPaginationQuery,
  util: { resetApiState: resetPersonelStoreAPI },
} = personelStoreAPI;
